<template>
  <div class="administrators-create page-layout">
    <PageHeader
      title="Création d'un administrateur"
      :has-back="true"
      @back="goBack"
    />
    <md-card class="meep-form">
      <!-- le formulaire de création -->
      <md-card-content>
        <div class="meep-form-column">
          <div class="meep-input">
            <!-- Cabinet -->
            <md-field
              :class="{ 'md-invalid': errors.has('organization_name') }"
            >
              <label>Cabinet *</label>
              <md-input
                id="chooseOrganizationInput"
                v-model="chosenOrganization.name"
                v-validate="'required'"
                name="organization_name"
                onpaste="return false;"
                onkeydown="return false;"
                @focus="openChooseOrganizationModal"
                type="text"
              >
              </md-input>
              <span class="md-error" v-show="errors.has('organization_name')">
                {{ errors.first("organization_name") }}
              </span>
            </md-field>
          </div>

          <div class="meep-input">
            <!-- Prénom -->
            <md-field :class="{ 'md-invalid': errors.has('firstname') }">
              <label>Prénom *</label>
              <md-input
                v-model="form.firstname"
                v-validate="'required|alpha_spaces'"
                name="firstname"
                type="text"
              >
              </md-input>
              <span class="md-error" v-show="errors.has('firstname')">
                {{ errors.first("firstname") }}
              </span>
            </md-field>
          </div>

          <div class="meep-input">
            <!-- Nom -->
            <md-field :class="{ 'md-invalid': errors.has('lastname') }">
              <label>Nom *</label>
              <md-input
                v-model="form.lastname"
                v-validate="'required|alpha_spaces'"
                name="lastname"
                type="text"
              >
              </md-input>
              <span class="md-error" v-show="errors.has('lastname')">
                {{ errors.first("lastname") }}
              </span>
            </md-field>
          </div>

          <div class="meep-input">
            <!-- Email -->
            <md-field :class="{ 'md-invalid': errors.has('email') }">
              <label>E-mail *</label>
              <md-input
                v-model="form.email"
                v-validate="'required|email'"
                name="email"
                type="email"
              >
              </md-input>
              <span class="md-error" v-show="errors.has('email')">
                {{ errors.first("email") }}
              </span>
            </md-field>
          </div>

          <h4>Informations complémentaires :</h4>
        </div>
        <div class="meep-form-column">
          <div class="meep-input">
            <!-- Téléphone fixe -->
            <md-field :class="{ 'md-invalid': errors.has('telfixe') }">
              <label>Téléphone fixe</label>
              <md-input
                v-model="form.telfixe"
                v-validate="'telephone'"
                name="telfixe"
                type="tel"
              >
              </md-input>
              <span class="md-error" v-show="errors.has('telfixe')">
                {{ errors.first("telfixe") }}
              </span>
            </md-field>
          </div>

          <div class="meep-input">
            <!-- Téléphone portable  -->
            <md-field :class="{ 'md-invalid': errors.has('telportable') }">
              <label>Téléphone portable</label>
              <md-input
                v-model="form.telportable"
                v-validate="'telephone'"
                name="telportable"
                type="tel"
              >
              </md-input>
              <span class="md-error" v-show="errors.has('telportable')">
                {{ errors.first("telportable") }}
              </span>
            </md-field>
          </div>

          <div class="meep-input">
            <!-- Numéro et voie -->
            <md-field>
              <label>Adresse</label>
              <md-input v-model="form.adresse" name="adresse" type="text">
              </md-input>
            </md-field>
          </div>

          <div class="meep-input">
            <!-- Ville -->
            <md-field :class="{ 'md-invalid': errors.has('ville') }">
              <label>Ville</label>
              <md-input
                v-model="form.ville"
                v-validate="'alpha_spaces'"
                name="ville"
                type="ville"
              >
              </md-input>
              <span class="md-error" v-show="errors.has('ville')">
                {{ errors.first("ville") }}
              </span>
            </md-field>
          </div>

          <div class="meep-input">
            <!-- Code postal -->
            <md-field :class="{ 'md-invalid': errors.has('codepostal') }">
              <label>Code postal</label>
              <md-input
                v-model="form.codepostal"
                v-validate="'numeric|min:5|max:5'"
                name="codepostal"
                type="text"
              >
              </md-input>
              <span class="md-error" v-show="errors.has('codepostal')">
                {{ errors.first("codepostal") }}
              </span>
            </md-field>
          </div>

          <div class="md-layout md-alignment-center-right">
            <md-button
              class="md-raised md-primary"
              @click.native="createAdministrator"
            >
              Créer un administrateur
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <!-- Modal pour sélectionner un cabinet -->
    <choose-item-modal
      v-show="isChooseOrganizationModalOpen"
      :itemsList="organizationsList"
      :chosenItem="chosenOrganization"
      :text="chooseItemModalText"
      :isDebug="isDebug"
      @close="closeChooseOrganizationModal"
      @saved="setOrganization"
    >
    </choose-item-modal>
  </div>
</template>

<script>
/* Composants */
import chooseItemModal from "../../../components/modal/choose-item";

/* Modèles */
import usersModel from "../../../model/users";
import organizationsModel from "../../../model/organizations";
import PageHeader from "@/components/PageHeader";

export default {
  data() {
    return {
      isDebug: false,
      isChooseOrganizationModalOpen: false,
      /* Tampon pour passer le cabinet en prop du modal */
      chosenOrganization: {
        id: null,
        name: "",
      },
      form: {
        scope: 1,
        organization_id: "",
        firstname: "",
        lastname: "",
        email: "",
        telfixe: "",
        telportable: "",
        adresse: "",
        ville: "",
        codepostal: "",
        logo: null,
      },
      chooseItemModalText: {
        header: "Choisir un cabinet :",
      },
      organizationsList: [],
    };
  },
  methods: {
    openChooseOrganizationModal() {
      this.isChooseOrganizationModalOpen = true;
    },
    closeChooseOrganizationModal() {
      this.isChooseOrganizationModalOpen = false;
    },
    setOrganization(organization) {
      /* Assigner le tampon */
      this.chosenOrganization = organization;
      /* Assigner également l'id dans le formulaire */
      this.form.organization_id = organization.id;
    },
    goBack() {
      window.history.back();
    },
    createAdministrator() {
      this.$validator.validateAll().then(result => {
        if (result) {
          usersModel
            .create(this.form)
            .then(() => {
              this.$toasted.global.AppSucces({
                message: "L'administrateur a bien été créé",
              });

              this.$router.push("/admin/administrators/");
            })
            .catch(err => {
              this.$toasted.global.AppError({
                message: err.msg,
              });
            });
        } else {
          this.$toasted.global.AppInfo({
            message: "Vous devez remplir les champs manquants",
          });
        }
      });
    },
  },
  mounted() {
    /* Récupérer tout les cabinets */
    organizationsModel.getAll().then(organizations => {
      this.organizationsList = organizations;
    });
  },
  components: {
    PageHeader,
    "choose-item-modal": chooseItemModal,
  },
};
</script>

<style lang="scss" scoped>
.administrators-create {
  #chooseOrganizationInput {
    color: #fff;
    text-shadow: 0px 0px 1px #fff;
  }

  .debug-log {
    max-height: 360px;
    overflow-y: scroll;
  }

  .md-card {
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
