<template>
  <modal class="choose-item-modal" @close="$emit('close')">
    <!-- Titre -->
    <div class="modal-header">
      <h3>{{ text.header }}</h3>
    </div>

    <!-- Corps -->
    <div class="modal-body">
      <search-bar
        modifier="large"
        placeholder="Rechercher dans la liste"
        v-model="query"
      >
      </search-bar>

      <md-list class="modal-list">
        <md-list-item v-for="item in filter" :key="item.id">
          <span>
            {{ item.name }}
          </span>

          <md-radio v-model="chosenItemID" :value="item.id"> </md-radio>
        </md-list-item>
      </md-list>
    </div>

    <div class="modal-footer">
      <md-button @click.native="$emit('close')">
        Annuler
      </md-button>

      <md-button class="md-raised md-primary" @click.native="onSave">
        Valider
      </md-button>
    </div>
  </modal>
</template>

<script>
export default {
  props: {
    /* La liste des items que l'on peut sélectionner */
    itemsList: {
      type: Array,
      required: true,
    },
    /* L'identifiant des items déjà selectionnés */
    chosenItem: {
      type: Object,
      required: false,
    },
    /* Le texte à afficher (titre, etc...) */
    text: {
      type: Object,
      required: true,
    },
    isDebug: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chosenItemID: this.chosenItem.id,
      query: "",
    };
  },
  methods: {
    /* Renvoyer l'item selectionné et fermer le modal */
    onSave() {
      let chosenItem = this.itemsList.find(item => {
        return item.id == this.chosenItemID;
      });

      this.$emit("saved", chosenItem);
      this.$emit("close");
    },
  },
  computed: {
    filter() {
      if (this.query === "") {
        return this.itemsList;
      }

      return this.itemsList.filter(item => {
        return item.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
      });
    },
  },
};
</script>

<style lang="scss">
.choose-item-modal {
  .modal-container {
    width: 465px;
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  }

  .modal-body {
    padding: 0 20px;
    p {
      margin: 5px 0;
    }
  }

  .modal-footer {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .modal-list {
    max-height: 420px;
    overflow-y: scroll;
  }

  .debug-log {
    max-height: 15vh;
    overflow-y: scroll;
  }
}
</style>
