import fetch from "../services/fetch";
import store from "../store";

export default {
    get,
    getAmountNews,
    getAll,
    getTrashed,
    create,
    update,
    updateAmountNews,
    putInTrash,
    restore,
    remove,
};

function get(id) {
    return fetch("api/organizations/" + id, {
        method  : "get",
        headers : { "Authorization" : store.state.token },
    });
}

function getAmountNews() {
    return fetch("api/organizations/amount-news/", {
        method  : "get",
        headers : { "Authorization" : store.state.token },
    });
}

function getAll() {
    return fetch("api/organizations/", {
        method  : "get",
        headers : { "Authorization" : store.state.token },
    });
}


function getTrashed() {
    return fetch("api/organizations/get-trashed", {
        method  : "post",
        headers : {
            "Authorization" : store.state.token,
        },
    });
}

function create(organization) {
    return fetch("api/organizations/", {
        method  : "post",
        headers : {
            "Authorization" : store.state.token,
            "Content-Type"  : "application/json",
        },
        body    : JSON.stringify(organization),
    });
}

function update(organization) {
    return fetch("api/organizations/" + organization.id, {
        method  : "put",
        headers : {
            "Authorization" : store.state.token,
            "Content-Type"  : "application/json",
        },
        body    : JSON.stringify(organization),
    });
}

function updateAmountNews(organization) {
    return fetch("api/organizations/amount-news/" + organization.id, {
        method  : "put",
        headers : {
            "Authorization" : store.state.token,
            "Content-Type"  : "application/json",
        },
        body    : JSON.stringify(organization),
    });
}

/* Met le flag "is_trashed" du cabinets à 1 */
function putInTrash(id) {
    return fetch("api/organizations/put-in-trash", {
        method  : "post",
        headers : {
            "Authorization" : store.state.token,
            "Content-Type"  : "application/json",
        },
        body    : JSON.stringify({ id : id }),
    });
}

function restore(id) {
    return fetch("api/organizations/restore", {
        method  : "post",
        headers : {
            "Authorization" : store.state.token,
            "Content-Type"  : "application/json",
        },
        body    : JSON.stringify({ id : id }),
    });
}

function remove(id) {
    return fetch("api/organizations/" + id, {
        method  : "delete",
        headers : { "Authorization" : store.state.token },
    });
}
